<template>
  <v-card>
    <form @submit.prevent="validateAndSubmit">
      <v-img
        class="white--text ma-4"
        contain
        height="200px"
        src="../../assets/logo.jpg"
      />

      <v-card-title primary-title>
        Wachtwoord instellen
      </v-card-title>

      <v-card-text v-if="loading">
        Gegevens worden geladen.
      </v-card-text>

      <v-card-text v-else-if="setPasswordRequest">
        <v-row>
          <v-col>
            <v-text-field
              v-model="newPassword.value"
              :error-messages="newPassword.errors"
              label="Nieuw wachtwoord"
              type="password"
              @blur="validateNewPassword"
              @input="newPassword.errors = []"
            />

            <v-text-field
              v-model="newPasswordRepeat.value"
              :error-messages="newPasswordRepeat.errors"
              label="Herhaal"
              type="password"
              @blur="validateNewPasswordRepeat"
              @input="newPasswordRepeat.errors = []"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              color="primary"
              depressed
              type="submit"
            >
              Opslaan
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WolkSetPassword',
  data() {
    return {
      loading: false,
      setPasswordRequest: null,
      newPassword: {
        value: '',
        errors: [],
      },
      newPasswordRepeat: {
        value: '',
        errors: [],
      },
      specialChars: [
        '!', '#', '$', '%', '&', '(', ')', '*', '+',
        '-', '/', ':', ';', '<', '=', '>', '?', '@',
        '[', '\\', ']', '^', '_', '{', '|', '}', '~',
      ],
    };
  },
  beforeMount() {
    this.loadSetPasswordRequest();
  },
  methods: {
    loadSetPasswordRequest() {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/set-password-requests/${this.$route.params.setPasswordRequestPublicId}`;

      axios({
        method: 'get',
        url,
      })
        .then((response) => {
          if (!response.data.setPasswordRequest) {
            this.$store.commit('app/snackbarText', {
              text: 'Deze pagina waarschijnlijk verlopen. Vraag een nieuw link aan.',
              visible: true,
            });
          }

          this.setPasswordRequest = response.data.setPasswordRequest;
        })
        .catch(() => {
          this.$store.commit('app/snackbarText', {
            text: 'Deze pagina waarschijnlijk verlopen. Vraag een nieuw link aan.',
            visible: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateNewPassword() {
      this.newPassword.value = this.newPassword.value.trim();

      const newErrors = [];
      if (!this.newPassword.value || typeof this.newPassword.value !== 'string') {
        newErrors.push('Is verplicht');
        this.newPassword.errors = newErrors;
        return;
      }

      if (this.newPassword.value.length < 8) {
        newErrors.push('Dient minimaal 8 karacters te zijn');
        this.newPassword.errors = newErrors;
        return;
      }

      const foundSpecialChars = this.newPassword.value.split('').filter((char) => this.specialChars.includes(char));

      if (foundSpecialChars.length === 0) {
        newErrors.push('Dient minimaal 1 speciaal teken te hebben (!, #, $, %, &, (, ), * of +).');
        this.newPassword.errors = newErrors;
        return;
      }

      const lowerCaseRegex = /[a-z]/g;
      const foundLowerCaseChars = this.newPassword.value.match(lowerCaseRegex);

      if (foundLowerCaseChars === null || foundLowerCaseChars.length === 0) {
        newErrors.push('Dient minimaal 1 kleine letter te bevatten.');
        this.newPassword.errors = newErrors;
        return;
      }

      const upperCaseRegex = /[A-Z]/g;
      const foundUpperCaseChars = this.newPassword.value.match(upperCaseRegex);

      if (foundUpperCaseChars === null || foundUpperCaseChars.length === 0) {
        newErrors.push('Dient minimaal 1 hoofdletter te bevatten.');
        this.newPassword.errors = newErrors;
      }
    },
    validateNewPasswordRepeat() {
      this.newPasswordRepeat.value = this.newPasswordRepeat.value.trim();

      const newErrors = [];
      if (!this.newPasswordRepeat.value) {
        newErrors.push('Is verplicht');
        this.newPasswordRepeat.errors = newErrors;
        return;
      }

      if (this.newPasswordRepeat.value !== this.newPassword.value) {
        newErrors.push('Is niet gelijk');
        this.newPasswordRepeat.errors = newErrors;
      }
    },
    validateAndSubmit() {
      this.validateNewPassword();
      this.validateNewPasswordRepeat();

      if (this.newPassword.errors.length > 0) return;
      if (this.newPasswordRepeat.errors.length > 0) return;

      this.submit();
    },
    submit() {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/set-password-requests/${this.$route.params.setPasswordRequestPublicId}/submit`;

      axios({
        method: 'post',
        url,
        data: {
          password: this.newPassword.value,
        },
      })
        .then((response) => {
          const { setPasswordRequest } = response.data;

          if (!setPasswordRequest) {
            throw new Error('setPasswordRequest not returned');
          }

          this.$store.commit('app/snackbarText', {
            text: 'Nieuw wachtwoord is ingesteld, je kunt nu inloggen.',
            visible: true,
          });

          this.loading = false;

          this.$router.push({ name: 'signIn' });
        })
        .catch((error) => {
          console.log(`setPasswordRequest error: ${error}`);

          this.$store.commit('app/snackbarText', {
            text: 'Er is iets fout gegaan. Vraag een nieuw link aan.',
            visible: true,
          });

          this.$router.push({ name: 'newSetPasswordRequest' });

          this.loading = false;
        });
    },
  },
};
</script>
