<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="10"
        style="max-width: 400px; margin-top: 100px;"
      >
        <SetPassword />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SetPassword from '../components/auth/SetPassword.vue';

export default {
  name: 'WolkSetPasswordPage',
  components: {
    SetPassword,
  },
};
</script>
